import type { FC } from 'react';
import { SkipRenderOnServer } from '../../utils/SkipRenderOnServer';
import { ClientOnlyModal, IModalProps } from './internal/ClientOnlyModal';

export type { IModalProps };

/**
 * Modal component provides a solid foundation for creating dialogs, popovers,
 * lightboxes or anything else.
 *
 * @example
 *
 * ```tsx
 * <Modal isOpen={opened} onClose={closeHandler}>
 *   <ModalHeader>
 *     <h2>Modal Title</h2>
 *   </ModalHeader>
 *   <Card>
 *     <p>Modal content</p>
 *   </Card>
 * </Modal>
 * ```
 */
export const Modal: FC<IModalProps> = (props) => {
  return (
    <SkipRenderOnServer>
      <ClientOnlyModal {...props} />
    </SkipRenderOnServer>
  );
};
